<template>
    <div class="custmers-rank">
        <div class="custmers-rank-box">
            <div class="right-wrap-item">
                <span class="item-title-h2">全部客户职级</span>
            </div>

            <el-row>
                <el-tabs :tab-position="'left'" class="customerRank" :class="{normal : stage === 1}">
                    <el-tab-pane label="popper-append-to-body" disabled>
                        <span slot="label" class="tab-list__btn">
                            <el-popover
                                popper-class="popover-select"
                                placement="bottom"
                                width="290"
                                trigger="click"
                                @after-leave="clearInput"
                                content="">
                                    <el-button slot="reference" class="chose-customer">
                                        <i class="icon-plus primary el-icon--left">选择客户</i>
                                    </el-button>
                                    <slot>
                                        <div class="el-input">
                                            <input type="text"
                                                v-model="search"
                                                autocomplete="off"
                                                placeholder="请输入搜索字段"
                                                class="el-input__inner">
                                        </div>

                                        <ul>
                                            <li
                                                class="text-ellipsis"
                                                v-for="item in customer"
                                                :key="item.customerId"
                                                :title="item.customerName"
                                                v-if="!item.isHasGrade && (item.customerName.indexOf(search) !== -1)"
                                                @click="addCustomer(item)">
                                                {{ item.customerName }}
                                            </li>
                                        </ul>
                                    </slot>
                            </el-popover>
                        </span>

                        <el-row v-if="isDefault">
                            <div class="default">
                                <i class="table-center">
                                    暂无职级数据
                                </i>
                                <span class="table-center">
                                    请添加客户后，即可编辑该客户职级了哦～
                                </span>

                                <el-button class="add-customer-btn table-center" @click.native="addCustomerBtn">
                                    <i class="el-icon--left icon-plus">添加客户</i>
                                </el-button>

                                <div class="table-center">
                                    <img src="../../assets/images/customer/pic_emptydate.png" alt="">
                                </div>
                            </div>
                        </el-row>
                    </el-tab-pane>

                    <el-tab-pane
                        v-for="(item, index) in customerEditList"
                        v-if="item.isHasGrade"
                        :key="'item_' + index"
                    >
                        <span slot="label">
                            <div
                                @click="tabChange(item)"
                                class="text-ellipsis"
                                :title="item.customerName"
                                :class="{'active' : item.isActive}">
                                {{ item.customerName }}
                            </div>
                        </span>
                        <el-row>
                            <div class="right-wrap-item">
                                <span class="item-title-h1">{{ item.customerName }} （<span
                                    class="orange"> {{ total }} </span>）</span>

                                <el-button
                                    v-if="stage === 2"
                                    class="btn-newJobRank"
                                    type="primary"
                                    @click="addJobRank(0,item)">
                                    <i class="el-icon--left icon-plus">
                                        新增职级
                                    </i>
                                </el-button>
                            </div>
                        </el-row>

                        <el-row>
                            <el-table
                                v-loading="loading"
                                :data="tableData"
                                style="width: 100%;min-height: 400px;">
                                <el-table-column
                                    prop="name"
                                    label="职级"
                                    width="260">
                                    <template slot-scope="scope">
                                        <span :title="scope.row.name">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="salary"
                                    label="薪酬区间"
                                    width="240">
                                    <template slot-scope="scope">
                                        <span :title="scope.row.salary">{{ scope.row.salary }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="discription"
                                    label="相关补充"
                                    width="220">
                                    <template slot-scope="scope">
                                        <span :title="scope.row.discription">{{ scope.row.discription }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="updater"
                                    label="创建人/更新人"
                                    width="250">
                                    <template slot-scope="scope">
                                        <span :title="scope.row.updater">{{ scope.row.updater }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    v-if="stage === 2"
                                    prop="menu"
                                    label="操作">
                                    <template slot-scope="scope">
                                        <el-button class="rank-operate-btn" type="text" size="medium" @click="addJobRank(1,scope.row)">编辑</el-button>
                                        <el-button class="rank-operate-btn" type="text" size="medium" @click="delJobRank(scope.row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>

                        <el-pagination
                            class="pagination-common"
                            :current-page="page.current"
                            layout="total, prev, pager, next, slot"
                            :total="total"
                            v-if="total && total > page.take"
                            @current-change="handleCurrentChange">
                            <span class="pagination-text">
                                <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                                <span @click="handlePagerJump">跳转</span>
                            </span>
                        </el-pagination>

                    </el-tab-pane>
                </el-tabs>
            </el-row>

            <addJobRankDialog ref="addJobRankDialog" :title="title" @successFn="addJobRankSuccessded"></addJobRankDialog>
        </div>

        <div class="lbd-sign"></div>
    </div>
</template>

<script>
    import addJobRankDialog from '#/component/common/dialog/addJobRank-dialog.vue';
    import customerService from '#/js/service/customerService.js';

    export default {
        name: "addCustomersRank",
        components: {
            addJobRankDialog,
        },
        data() {
            return {
                tableData: [],
                page: {
                    current: 1,
                    take: 10,
                },
                pagerJump: 0,
                total: 0,
                activeItem: {},
                search: '',
                activatedTab: {},
                customer: [],
                customerEditList: [],
                loading: false,
                title: '新增职级',
                isDefault: true,
                stage: 1,
                getCustomerGradeCancelFn: () => {
                }
            }
        },
        created() {
            let userInfo = this.$store.state.user.userInfo;

            customerService
                .gradeCustomers()
                .then(res => {
                    res.map(item => {
                        if (item.isHasGrade) {
                            this.isDefault = false;
                            this.customerEditList.unshift(item);
                        }
                        item.isActive = false;
                    });
                    this.customer = res;
                    setTimeout(function () {
                        $('#tab-1 span div').click();
                    }, 0);
                });
            window.vuetest = this;
            if (userInfo.isAdministrator || userInfo.isAdminOrTeamLeader) {
                this.stage = 2;
            }
            if (this.stage === 1) {
                $('#tab-0').height(0)
            }
        },
        methods: {
            addJobRank(type, data) {
                this.title = type === 0 ? '添加职级' : '编辑职级';
                this.$refs.addJobRankDialog.showHandle(type === 0 ? {
                    customerId: data.customerId || '',
                    customerName: data.customerName || ''
                } : {
                    customerId: data.customerId || '',
                    customerName: data.customerName || '',
                    name: data.name || '',
                    maxSalary: data.maxSalary,
                    minSalary: data.minSalary,
                    discription: data.discription,
                    id: data.id || ''
                });
            },
            delJobRank(data) {
                this.$confirm(`<span>确定删除职级<i class="primary">${data.name}</i>吗？删除后不可恢复。</span>`, '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'confirm',
                    dangerouslyUseHTMLString: true,
                    center: true,
                    customClass: 'customerRank-confirm',
                    cancelButtonClass: 'btn-cancel'
                }).then(() => {
                    customerService
                        .deleteGrade(data.id)
                        .then(res => {
                            shortTips('职级删除成功');
                            this.getCustomerGrade();
                        }).catch(err => {
                            console.log(err)
                        })
                }, () => {
                    this.$message.warn('删除已取消。');
                })
            },
            tabChange(vm) {
                this.tableData = [];
                this.page.current = 1;
                vm.customerName ? this.activatedTab = {
                    customerId: vm.customerId,
                    customerName: vm.customerName,
                } : false;

                if (!this.activeItem.customerName) {
                    this.activeItem = vm;
                    vm.isActive = true;
                } else {
                    this.activeItem.isActive = false;
                    this.activeItem = vm;
                    vm.isActive = true;
                }

                this.getCustomerGrade();
                
                document.querySelector('.custmers-rank-box').scrollTop = 0;
            },
            async getCustomerGrade() {
                this.getCustomerGradeCancelFn();
                if (!this.activeItem.customerId) {
                    return false;
                }
                this.loading = true;
                await customerService
                    .getCustomerGrade({
                        customerId: this.activeItem.customerId,
                        start: this.page.take * (this.page.current - 1),
                        take: 10
                    }, this)
                    .then(res => {
                        res.list.map(item => {
                            item['salary'] = `${item.minSalary}K - ${item.maxSalary}K`;
                            item['customerName'] = this.activeItem.customerName;
                        });
                        this.tableData = res.list;
                        this.total = res.total;
                    })
                this.loading = false;
            },
            handleCurrentChange(val) {
                this.page.current = val;
                this.getCustomerGrade();
            },
            handlePagerJump(){
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.total/this.page.take);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.handleCurrentChange(currentPager)
                }
            },
            addJobRankSuccessded(res) {
                this.getCustomerGrade();
                console.log(res);
                shortTips(this.title == '编辑职级' ? '修改职级成功！' : '新增职级成功！');
            },
            addCustomer(item) {
                if (!item.isHasGrade) {
                    this.tableData = [];
                    this.total = 0;
                    this.isDefault = false;
                    $('.chose-customer').click();
                    item.isHasGrade = true;
                    this.activeItem.isActive = false;
                    this.activeItem = item;
                    item.isActive = true;
                    this.customerEditList.unshift(item);

                    return setTimeout(function () {
                        $('.el-tabs__item .active').parent().parent().click();
                    }, 0)
                }
            },
            addCustomerBtn() {
                if (this.stage === 1) {
                    return false;
                }
                setTimeout(function () {
                    $('.chose-customer').click();
                }, 100);
            },
            clearInput() {
                this.search = '';
            }
        }
    }
</script>

<style lang="scss">
    // .popover-select {
    //     ul {
    //         max-height: 400px;
    //         overflow-y: auto;
    //         overflow-x: hidden;
    //     }

    //     li {
    //         padding: 10px 30px;
    //         line-height: 20px;
    //         text-align: left;

    //         &:hover {
    //             color: $primary;
    //             background-color: rgba($primary, .1);
    //         }
    //     }

    //     .el-input {
    //         padding: 10px;
    //         border-bottom: 1px solid #eee;
    //     }
    // }

    .popover-select {
        padding: 0;
        width: 290px;
        border-radius: 2px;
        background-color: #fff;

        .popper__arrow {
            display: none;
        }
    }

    .normal #tab-0 {
        display: none;
    }

    .el-table{
        .el-button{
            min-width: auto;
            &.rank-operate-btn {
                padding: 0;
            }
        }
    }

    .customerRank-confirm {
        width: 608px;
        padding-bottom: 80px;

        .el-message-box__message {
            margin-top: 50px;
            margin-bottom: 63px;
        }

        .el-message-box__content {
            font-size: 16px;
            line-height: 20px;
        }

        i.primary {
            margin: 0 4px;
            font-size: 16px;
            color: $primary;
        }

        .el-message-box__headerbtn {
            display: none;
        }

        .el-button {
            width: 100px;
            min-width: auto !important;
            padding: 0;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            border: none;

            outline: none !important;

            &.el-button--primary:hover {
                background-color: #36b495;
                border-color: #38bc9c;
            }
        }

        .btn-cancel {
            margin-right: 50px;
            background: #eee;
            color: #999999;

            &:hover {
                background: #ddd;
            }
        }
    }
</style>

<style lang="scss" scoped>
.popover-select {
    ul {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    li {
        padding: 10px 30px;
        line-height: 20px;
        text-align: left;

        &:hover {
            color: $primary;
            background-color: rgba($primary, .1);
        }
    }

    .el-input {
        padding: 10px;
        border-bottom: 1px solid #eee;
    }
}

.custmers-rank {
    padding: 20px;
    height: 100%;
    // overflow-y: auto;
    .custmers-rank-box {
        margin-bottom: 20px;
        background-color: #ffffff;
        height: 100%;
        border-radius: 8px;
        overflow-y: auto;
        & > .right-wrap-item {
            // padding: 0 0 16px 0;
            padding: 0 37px;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #EEEEEE;

            & > .item-title-h1 {
                font-size: 18px;
            }
        }

        .el-tab-pane {
            margin-right: 20px;
        }

        /deep/ .modal-box-wrapper {
            width: 608px;
        }

        .btn-newJobRank {
            float: right;
            width: 120px;
        }

        .orange {
            font-size: 16px;
            color: #ff6601;
        }

        .table-center {
            display: table;
            margin: auto;
        }

        .right-wrap-item {
            margin-top: 20px;
            position: relative;

            .item-title-h2 {
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                line-height: 21px;
                // color: rgb(68, 68, 68);
                color: #999999;

                // &::before {
                //     content: "";
                //     display: inline-block;
                //     width: 8px;
                //     height: 18px;
                //     margin-right: 10px;
                //     background-color: rgb(56, 188, 157);
                // }
            }
        }

        .el-pagination.pagination-common {
            margin-top: 20px;
            text-align: right;
        }

        .customerRank /deep/ {

            .el-tabs__header.is-left {
                margin-right: 20px;
                border-right: 1px solid #EEEEEE;
            }

            .right-wrap-item {
                overflow: hidden;
                margin-bottom: 10px;
            }

            .el-tabs__nav-wrap:after {
                display: none;
            }

            .el-tabs__item {
                padding: 0;
                width: 290px;
                height: auto;
                min-height: 40px;
                text-align: center;
                // background: #F8F8F8;
                color: #444444;

                transition: .3s;

                .text-ellipsis {
                    padding: 0 30px;
                    text-align: left;
                }

                &:nth-child(2) {
                    // background-color: #F8F8F8 !important;
                    color: $primary !important;
                }

                &:hover {
                    background-color: rgba($primary, .1);
                    color: $primary;
                }

                &.is-active {
                    background-color: rgba($primary, .1);
                    color: $primary;
                }
            }

            .tab-list__btn {
                display: block;
                width: 100%;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #EEE;
                color: $primary;
            }

            .el-tabs__active-bar {
                display: none;
            }

            .el-table {
                &::before {
                    width: 0 !important;
                }
                .el-table__body {
                    border-collapse: collapse;

                    tr:hover > td {
                        background-color: #F8F8F8;
                    }
                }

                th {
                    height: 40px;
                    background-color: #F8F8F8;
                    color: #444;
                    font-weight: normal;
                    text-shadow: 0 0 0;
                    border-left: 1px solid #EEE;
                    &:first-child {
                        border-left: none;
                    }
                }

                td {
                    padding: 6px 0;
                    // border-bottom: none;
                    border-bottom: 1px solid #EEE;
                    border-right: 1px solid #EEE;
                    &:last-child {
                        border-right: none;
                    }

                    .cell {
                        white-space: nowrap;
                    }
                }

                // .el-table__row {
                //     border-bottom: 1px solid #EEE;
                // }
            }
        }

        .chose-customer {
            border: none;
            // background-color: #F8F8F8;
            background-color: transparent;
            color: #38BC9D;
        }

        .default {
            margin-top: 85px;

            i.table-center {
                margin-bottom: 10px;
                font-size: 18px;
                color: #444;
            }

            span.table-center {
                margin-bottom: 30px;
                color: #666;
            }
        }

        .add-customer-btn {
            margin-bottom: 63px;
            padding: 10px 22px;
            border: none;
            background-color: $primary;
            color: #fff;
        }

        .icon-plus {
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                top: 2px;
                margin-right: 4px;
                width: 14px;
                height: 14px;
                background-image: url(../../assets/images/customer/ic_add.png);
            }

            &.primary:before {
                background-image: url(../../assets/images/customer/ic_add_copy.png);
            }
        }
    }

    .lbd-sign {
        padding-bottom: 20px;
        &:after {
            content: '© 2023 liebide.com';
            margin-left: 50%;
            text-align: center;
            font-size: 14px;
            color: #999;
        }
    }
}
</style>
<style lang="scss">

    .popover-select {
        padding: 0;
        width: 290px;
        border-radius: 2px;
        background-color: #fff;

        .popper__arrow {
            display: none;
        }
    }

    .normal #tab-0 {
        display: none;
    }

    .el-table{
        .el-button{
            min-width: auto;
            &.rank-operate-btn {
                padding: 0;
            }
        }
        .has-gutter {
            tr th:first-child {
                padding-left: 60px;
            }
        }
        .el-table__body {
            tbody .el-table__row td:first-child {
                padding-left: 60px !important;
            }
        }
    }

    .customerRank-confirm {
        width: 608px;
        padding-bottom: 80px;

        .el-message-box__message {
            margin-top: 50px;
            margin-bottom: 63px;
        }

        .el-message-box__content {
            font-size: 16px;
            line-height: 20px;
        }

        i.primary {
            margin: 0 4px;
            font-size: 16px;
            color: $primary;
        }

        .el-message-box__headerbtn {
            display: none;
        }

        .el-button {
            width: 100px;
            min-width: auto !important;
            padding: 0;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            border: none;

            outline: none !important;

            &.el-button--primary:hover {
                background-color: #36b495;
                border-color: #38bc9c;
            }
        }

        .btn-cancel {
            margin-right: 50px;
            background: #eee;
            color: #999999;

            &:hover {
                background: #ddd;
            }
        }
    }

</style>
